<template>
  <div>
    <lessons/>
  </div>
</template>

<script>
// @ is an alias to /src
import lessons from '@/components/lessons.vue'

export default {
  name: 'LessonsView',
  components: {
    lessons
  }
}
</script>
