<template>
  <div>
    <suzuki/>
  </div>
</template>

<script>
// @ is an alias to /src
import suzuki from '@/components/suzuki.vue'

export default {
  name: 'SuzukiView',
  components: {
    suzuki
  }
}
</script>
