<template>
  <div>
    <contact/>
  </div>
</template>

<script>
// @ is an alias to /src
import contact from '@/components/contact.vue'

export default {
  name: 'ContactView',
  components: {
    contact
  }
}
</script>
