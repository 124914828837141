<template>
  <div>
    <resources/>
  </div>
</template>

<script>
// @ is an alias to /src
import resources from '@/components/resources.vue'

export default {
  name: 'ResourcesView',
  components: {
    resources
  }
}
</script>
