<template>
  <div>

    <h1>Violin Lessons in Honolulu, Hawaii</h1>

    <img class="rphoto" alt="The Suzuki Method" src="../assets/04.jpg">

    <p><b>Violin Artistry Studio</b> teaches children of all ages and levels, incorporating the philosophy of the Suzuki Method™. Private lessons are tailored to each student's needs, interests and learning capabilities. If you are a parent of a young child, visit the <a href="https://violinartistry.com/suzuki-approach">Suzuki Approach</a> Page for more information.</p>
    <p>If you would like to take lessons with Dr. Nikki Routman Ebisu, please contact us and provide the student’s name, age and availability.</p>
    <p>Parents of younger students may schedule a consultation to address any questions, and the teacher will observe the child’s readiness to begin instruction.  If your desired time is not available, you may request to be added to our waiting list.</p>
    <p>Lessons are available in English and Japanese.</p>
    <br>
    <h2>Online & in-person lessons due to Covid-19</h2>
    <p>Due to the Covid-19 pandemic, lessons are being offered both in-person and online. It is recommended that beginners receive in-person lessons, due to the complexity of the instrument.</p>
    <p>Safety measures are being taken to sanitize the studio and ensure proper social distancing. Hand-sanitizers are readily available.</p>
    <br>

    <h1>Dr. Nikki Routman Ebisu</h1>

    <img class="lphoto" alt="Dr. Nikki Routman Ebisu" src="../assets/nikki_ebisu-267r_BW.jpg">





    <p>Violinist Dr. Nikki (Routman) Ebisu enjoys a distinguished career as a teacher, performer, and clinician. Her passion for music has led to a global career, featuring collaborative chamber music and orchestral performances across Europe, the USA, and Asia. Recognized as the <a href="https://www.hmta.org/news/2022/hmta-teacher-of-the-year-nikki-routman-ebisu" target="_blank" rel="noopener">'2022 Teacher of the Year'</a> by the Hawaii Music Teachers Association, she is in high demand as a Japanese bilingual violin instructor and for being among the remaining few who studied directly under Dr. Shinichi Suzuki, the founder of the Suzuki Method. </p>

    <p>As the owner of Violin Artistry Studio in Honolulu, Dr. Ebisu specializes in providing violin education for students aged 3 and up, catering to beginners through advanced levels. In Hawaii, she has performed with the Hawaii Symphony Orchestra, Honolulu Symphony, Maui Pops Orchestra, Hawaii Pops Orchestra, and as a session recording violinist with local artists. </p>

    <p>As accomplished educator, Dr. Ebisu has held positions as a part-time Lecturer in Violin at the University of Hawai'i at Mānoa, Assistant and Adjunct Instructor at the University of Colorado at Boulder, and faculty at Conservatory Music in the Mountains Summer Festival in Colorado.During the summer, she serves on the Violin Faculty at Interharmony Music Festival in Acqui Terme, Italy.</p>

    <p>Dr. Ebisu’s students consistently achieve acclaim in national and international competitions, excelling in programs like the Hawaii Youth Symphony program. Many have been featured in exclusive masterclasses with renowned soloists such as Sarah Chang, Ray Chen, Augustin Hadelich, Midori, Ryu Goto, Robert McDuffie and others. From  prestigious performances at Carnegie Hall to NPR “From the Top” features, her students continue to shine.</p>

    <p>With a Bachelor of Arts degree in Music and Pre-Medicine from the University of Texas at Austin, along with Master's and Doctorate degrees in Violin Performance from the University of Colorado at Boulder, Dr. Ebisu's primary instructors include Dr. Shinichi Suzuki, Sumiko Edo, John Arnold, Dr. Eugene Gratovich, and Oswald Lehnert.</p>

    <p>A committed advocate for music education, Dr. Ebisu shares her passion through recitals, conference engagements, and workshops. Under her leadership as President of the Hawaii Music Teachers Association, HMTA achieved the prestigious recognition of '2022 MTNA National State Chapter Affiliate of the Year.'</p>


    <!-- <p>Violinist Dr. Nikki (Routman) Ebisu enjoys a distinguished career as a teacher, performer and clinician. Her collaborative chamber music and orchestral performances have taken her all across the globe, from Europe, the USA and Asia.</p>
    <p>Dr. Nikki Ebisu was awarded the Hawaii Music Teachers Association's <a href="https://www.hmta.org/news/2022/hmta-teacher-of-the-year-nikki-routman-ebisu" target="_blank" rel="noopener">"2022 Teacher of the Year"</a>  for her contributions and service to the music community. She was also recently highlighted in a <a href="https://www.hmta.org/news/memberfeature-nikki-ebisu" target="_blank" rel="noopener">"Hawaii Music Teachers Association (HMTA) Member Feature.</a></p>
    <p>As an active music educator, she is in large demand in Hawaii – both as a Japanese bilingual violin instructor and also as one of the few remaining to have studied directly with Dr. Shinichi Suzuki, the founder of the Suzuki Method. As the owner of the Violin Artistry Studio, Ebisu specializes in violin education for students ages 3 and up, from beginner through advanced levels.</p>
    <p>For the last 18 years, she performed as a violinist with the Hawaii Symphony Orchestra, Honolulu Symphony and Maui Pops Orchestra. She also served as a part-time Lecturer in violin at the University of Hawai'i at Mānoa. During the summer, she teaches at the Interharmony Music Festival in Acqui Terma, Italy.</p>
    <p>Her students have won high recognition in competitions at the national and international level, and have been prizewinners of the Hawaii State Music Competition, Osaka International Music Competition, American Protégé International Competition at Carnegie Hall, Oahu Arts Center Mozart Competition, MTNA National Performance Competitions, and many others.</p>
    <p>Her students have been accepted to prestigious music festivals and schools, including the National Youth Orchestra (NYO) and (NYO2), the McDuffie School for Strings, the Aspen Music Festival, Bowdoin, Interlochen and Meadowmount in New York. In 2016, a student was selected to perform the 'Bach Concerto for 2 Violins in D minor' alongside violinist Midori Goto in a sold-out concert at the Blaisdell NBC. Others students have been featured on NPR's 'From the Top' Radio Program on several occasions.</p>
    <p>A majority of her students are members of the highly competitive Hawaii Youth Symphony program, several in Concertmaster positions over the years. Her students have performed in masterclasses for notable artists including Sarah Chang, Ray Chen, Ryu Goto, Robert McDuffie, Midori, Martin Beaver, Chee-Yun, Augustin Hadelich, Wei He, Paul Katz, Kristin Kim, Amy Moretti, Eric Silberger, Joseph Swensen, Victor Yampolsky, the Cavani Quartet and others.</p>
    <p>Prior to moving to Hawaii and specializing in Suzuki Method education, Dr. Ebisu worked primarily with college-aged students, serving as a lead Graduate Assistant and Adjunct Instructor at the University of Colorado at Boulder, and on faculty at Conservatory Music in the Mountains Summer Festival in Durango, Colorado.</p>
    <p>Dr. Ebisu began her musical journey at the age two, observing the lessons of other Suzuki students. Upon moving to Japan at age four, she officially began lessons with Dr. Shinichi Suzuki’s apprentices at the Suzuki Talent Education in Tokyo, visiting Matsumoto on occasion. Accepted into the Preparatory Division of the Toho Academy of Music in Tokyo at age eight while concurrently attending the International School of the Sacred Heart (ISSH), she was invited to study with Professor of Viola, Ms. Sumiko Edo. She later returned to the United States for high school and college.</p>
    <p>Dr. Ebisu holds a Bachelor of Arts degree in Music and Pre-Medicine from the University of Texas at Austin. She received her Masters in Music and Doctorate in Music Arts (Violin Performance) degrees from the University of Colorado at Boulder. Her primary violin teachers include Dr. Shinichi Suzuki, Sumiko Edo, Lacy McLarry, John Arnold, Dr. Eugene Gratovich and Oswald Lehnert. Some of her greatest influences in Suzuki pedagogy are Liz Arbus, Jenny Burton, Helen Brunner, Pat D'Ercole, Ed Kreitman, Ellie LeRoux, Allen Lieb, Nancy Lokken, Ed Sprunger and David Strom.</p>
    <p>Dr. Ebisu continues to perform recitals, attend conferences and present workshops regularly. She is the Past President (ex-Officio) of the Hawaii Music Teacher's Association, the Hawai’i State Chapter affiliate of MTNA. During her term as President, HMTA was recognized as the <b>2022 MTNA National State Chapter Affiliate of the Year.</b></p>
    <p>She is also an active member of the Suzuki Association of Hawaii and the Hawaii State Chapter of ASTA.</p> -->

  </div>
</template>

<script>
export default {
  name: 'home',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../assets/settings.scss";


</style>
