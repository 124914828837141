<template>
  <div>

    <h1>The Suzuki Approach</h1>
    <h2>What is the Suzuki Method?</h2>

    <img class="rphoto" alt="The Suzuki Method" src="../assets/duet.jpg">

    <p>The Suzuki Method is a music curriculum based on the philosophies of Dr. Shinichi Suzuki. He originally called it the "Mother Tongue Approach" but later came to be known as "Talent Education”. Dr. Suzuki was inspired by the fact that children so effortlessly learn to speak their native tongue. When a child learns to speak, the following factors are at work:</p>

    <ul>
      <li>Listening</li>
      <li>Motivation</li>
      <li>Repetition</li>
      <li>Step-by-step Mastery</li>
      <li>Memory</li>
      <li>Vocabulary</li>
      <li>Parental Involvement</li>
      <li>Love</li>
    </ul>

    <p>Dr. Suzuki applied these principles to the violin, believing that every child was capable of learning music aurally (like one's native language), and he developed the following ten philosophies for his method:</p>

    <ol>
      <li>Every Child Is Able to Learn</li>
      <li>The Early Beginning</li>
      <li>Parental Support</li>
      <li>Listening</li>
      <li>Listening by ear</li>
      <li>Observation and Imitation</li>
      <li>Encouragement</li>
      <li>Review and Internalization</li>
      <li>The Stimulating Environment</li>
      <li>Group Lessons</li>
    </ol>

    <p>The 'Suzuki Method' or ‘Talent Education’ refers to the development of skill, knowledge and character. The word for Talent in Japanese is 才能 (<i>Saino</i>) which means both "ability" and "talent". There is no distinction for the word as there is in English.</p>
    <br>

    <img id="dr" alt="Dr. Shinichi Suzuki" src="../assets/drsuzuki.jpg">
    <br><br>
    <p>Dr. Suzuki said: </p>
    <p><i>"Musical ability is not an inborn talent but an ability which can be developed. Any child who is properly trained can develop musical ability just as all children develop the ability to speak their mother tongue. The potential of every child is unlimited".</i></p>
    <br>
    <h2 class="clear">Where can I find more information about the Suzuki Method™?</h2>
    <p>Please visit the <a href="http://suzukiassociation.org/about/suzuki-method/" target="_blank" rel="noopener">Suzuki Association of America</a> (SAA) Website for detailed information. You can find Dr. Suzuki’s biography, the philosophy and curriculum information. Although Dr. Suzuki passed away in 1998 at age 99, his philosophies and Method have spread internationally, not just as a violin method, but also for viola, cello, double bass, piano, organ, guitar, flute, recorder, harp, voice, brass instruments and early childhood education (infant-toddlers).</p>

    <p>Also check out Dr. Ebisu's <a href="https://youtu.be/QGle86Y0zEw" target="_blank" rel="noopener">interview on Hawaii's KIKU TV</a> where she discusses the Suzuki Method in detail.</p>

  </div>
</template>

<script>
export default {
  name: 'suzuki'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../assets/settings.scss";

#dr {
  float: left;
  margin-right: 36px;
  margin-bottom: 36px;
  border-radius: 16px;
}

@media screen and (max-width: 600px) {
  #dr {
    width: 100%;
    margin-right: 0;
  }
}
</style>
