<template>
  <div id="plugin"></div>
</template>

<script>

export default {
  mounted() {
    const plugin = document.createElement("script");
    plugin.setAttribute("src","https://app.mymusicstaff.com/Widget/v2/Login.ashx");
    plugin.async = true;
    // document.body.appendChild(plugin);
    document.getElementById("plugin").appendChild(plugin);
  },
  name: 'login'
}
</script>



<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../assets/settings.scss";


@media screen and (max-width: 880px) {



}

</style>
