<template>
  <div>

    <h1>Resources</h1>

    <div class="card">
      <h2>Local Music Stores</h2>
      <h3>CK Violins</h3>
      <a href="https://ckviolins.com" target="_blank" rel="noopener">https://ckviolins.com</a>
      <span>1888 Kalakaua Ave. C-308</span>
      <span>Honolulu, HI 96815</span>
      <span>Phone: (808) 589-1188</span>
      <span>Owner's Name: Nicholas Zou</span>
      <span>Hours: 1-5pm on Monday-Saturday, or by appointment. Closed Sunday.</span>
      <br><br>
      <h3>Music Center of Hawaii</h3>
      <span>2347 S. Beretania Street Suite 200</span>
      <span>Honolulu HI 96826 </span>
      <span>Phone: (808) 596-8742</span>
      <span>Hours: 10:30am-5:30pm, Closed Sunday.</span>
      <br><br>
      <h3>Cremona Violin Hawaii </h3>
      <a href="https://www.cremonaviolinhawaii.com" target="_blank" rel="noopener">https://www.cremonaviolinhawaii.com</a>
      <span>765 Amana St Suite 202</span>
      <span>Honolulu, HI 96814</span>
      <span>Phone: (808) 352-4491</span>
      <span>Owner's Name: Michael Wong</span>
      <span>Hours: Tuesday-Saturday 1-5pm. Closed Sunday & Monday.</span>
      <br>
    </div>

    <div class="card">
      <h2>Local Organizations</h2>
      <span><a href="https://www.myhso.org/" target="_blank" rel="noopener">Hawaii Symphony Orchestra</a></span>
      <br>
      <span><a href="https://www.hmta.org/" target="_blank" rel="noopener">Hawaii Music Teachers Association (HMTA)</a></span>
      <br>
      <span><a href="https://www.suzukihawaii.org/" target="_blank" rel="noopener">Suzuki Association of Hawaii (SAH)</a></span>
      <br>
      <span><a href="https://www.hawaiiasta.com/" target="_blank" rel="noopener">Hawaii American String Teacher’s Association (HASTA)</a></span>
      <br>
      <span><a href="https://www.hiyouthsymphony.org/" target="_blank" rel="noopener">Hawaii Youth Symphony</a></span>
      <br>
      <span><a href="https://www.honoluluchambermusicseries.org/" target="_blank" rel="noopener">Honolulu Chamber Music Series</a></span>
      <br>
      <span><a href="https://www.chambermusichawaii.org/" target="_blank" rel="noopener">Chamber Music Hawaii</a></span>
      <br>
      <span><a href="https://www.hawaiiopera.org/" target="_blank" rel="noopener">Hawaii Opera Theater</a></span>
      <br>
      <span><a href="https://www.diamondheadtheatre.com/" target="_blank" rel="noopener">Diamond Head Theater</a></span>
      <br>
    </div>

    <div class="card">
    <img class="photo" alt="Teaching Philosophy" src="../assets/02.jpg">
    <img class="photo" alt="Teaching Philosophy" src="../assets/IMG_7248.png">
    <img class="photo" alt="Teaching Philosophy" src="../assets/03.jpg">
  </div>

    <div class="card">
      <h2>Favorite Websites</h2>
      <span><a href="http://www.thepracticeshoppe.com/" target="_blank" rel="noopener">The Practice Shoppe</a></span>
      <br>
      <span><a href="http://www.suzukiassociation.org/" target="_blank" rel="noopener">Suzuki Association of America (SAA)</a></span>
      <br>
    </div>

    

  </div>
</template>

<script>
export default {
  name: 'resources',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../assets/settings.scss";
span {
  display: block;
}

h3 {
  margin: 0 0 4px 0;
}

.card {
  vertical-align: top;
  display: inline-block;
  width: calc(33% - 64px);
  padding-right: 32px;
}

@media screen and (max-width: 1000px) {
  .card {
    width: 100%;
  }
}

</style>
