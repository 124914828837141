<template>
  <div id="app">
    <img id="logo" @click="route('/')" alt="Violin Artistry Studio" src="./assets/logo.png">

    <div id="hamburger"
      class="hamburger hamburger--squeeze dhidden"
      v-bind:class="{ 'is-active': menu }"
      @click="menutog()">

        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
    </div>

    <nav class="fade" v-bind:class="{'mhidden': !menu}">
      <div class="link" @click="route('/')" v-bind:class="{'active': nav == '/'}">Home</div>
      <div class="link" @click="route('/suzuki-approach')" v-bind:class="{'active': nav == '/suzuki-approach'}">Suzuki Approach</div>
      <div class="link" @click="route('/lessons')" v-bind:class="{'active': nav == '/lessons'}">Lessons</div>
      <div class="link" @click="route('/resources')" v-bind:class="{'active': nav == '/resources'}">Resources</div>
      <div class="link" @click="route('/login')" v-bind:class="{'active': nav == '/login'}">Login</div>
      <div class="link" @click="route('/contact')" v-bind:class="{'active': nav == '/contact'}">Contact</div>
    </nav>

    <router-view id="main" v-bind:class="{'mhidden': menu}"></router-view>

    <div id="footer" v-bind:class="{'mhidden': menu}">
      <h2>Violin Artistry Studio</h2>
      <span>Dr. Nikki Routman Ebisu, Director & Owner</span>
      <br>
      <span>1110 University Ave. Suite 305</span>
      <span>Honolulu, HI 96826</span>
    </div>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      menu: false,
      nav: ''
    }
  },
  mounted() {
    this.$router.afterEach(() => {
      this.nav = this.$route.path;
    })
  },
  methods: {
    route: function(path) {
      this.menu = false;
      this.nav = path;
      this.$router.push(path);
    },
    menutog: function() {
      this.menu = !this.menu;
    }
  }
}
</script>

<style lang="scss">
@import "./assets/hamburgers.css";
@import "./assets/settings.scss";

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
.fade {
	animation: fadein 0.4s;
}

body {
  margin: 0;
}

#app {
  font-family: $font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $color-text;
  font-size: 18px;
}

#logo {
  float: left;
  cursor: pointer;
  width: 200px;
  padding: 18px 0 0 48px;
  user-select: none;
}

nav {
  float: right;
  padding: 60px 30px 0 0;
}

a {
  color: $color-main;
}

#hamburger {
  user-select: none;
}

.hamburger {
  float: right;
  padding: 48px 48px 0 0;
}

.link {
  display: inline-block;
  padding: 18px;
  cursor: pointer;
  text-decoration: none;
  user-select: none;
}
.link:hover {
	transition: color 0.4s ease;
	color: $color-main;
}
.link:active {
	transition: color 0s;
	color: $color-light;
	outline: 0;
}

.active {
  color: $color-main;
}

#submenu {
  position: absolute;
  text-align: left;
}

#main {
  padding: 24px 48px 48px 48px;
  clear: both;
  text-align: left;
}

.rphoto {
  float: right;
  width: calc(50% - 36px);
  margin-left: 36px;
  margin-bottom: 36px;
  border-radius: 16px;
}
.lphoto {
  float: left;
  width: calc(50% - 36px);
  margin-right: 36px;
  margin-bottom: 36px;
  border-radius: 16px;
}
.photo {
  width: 100%;
  border-radius: 16px;
  margin-bottom: 16px;
}

#footer {
  clear: both;
  text-align: left;
  padding: 48px;
  background: $color-footer;
}
#footer span {
  display: block;
}

.dhidden {
  display: none;
}
.mhidden {
  display: block;
}

.clear {
  clear: both;
}

@media screen and (max-width: 880px) {
  .dhidden {
    display: block;
  }
  .mhidden {
    display: none;
  }
  #logo {
    width: 140px;
    padding-left: 24px;
  }
  .link {
    display: block;
    margin: 0 auto;
    font-size: 20px;
  }
  nav {
    position: absolute;
    top: 164px;
    width: 100%;
    padding: 0;
  }
  #submenu {
    display: block;
  }
  #main {
    padding: 24px;
  }
  .hamburger {
    padding-right: 24px;
  }
  #footer {
    padding-left: 24px;
  }
}

@media screen and (max-width: 640px) {
  .lphoto, .rphoto {
    float: none;
    width: 100%;
    margin: 0;
  }
}

</style>
