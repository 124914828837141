<template>
  <div>

    <h1>Contact Us</h1>

    <p>If you have any questions or would like to take lessons with Dr. Nikki Routman Ebisu, please contact us and provide the student’s name, age, and availability. Adult students are also welcome.  There may be a waiting list for a particular day or time.  Lessons are also available online via Zoom.</p>

    <div id="form" v-show="!submitted">
      <div class="card left">
        <h4>Name*</h4>
        <input v-model="name" type="text"/><br/>
      </div>

      <div class="card">
        <h4>Email*</h4>
        <input v-model="email" type="email"/><br/>
      </div>

      <h4>How may we help you?*</h4>
      <textarea v-model="message"></textarea><br/>

      <!-- <div id="recaptcha-main" class="g-recaptcha" data-sitekey="6Lca9O0fAAAAAOYOWN7R-tVq0DkcJGz0aaSrFPNE" data-callback="enableBtn"></div> -->
      <vue-recaptcha id="recaptcha" sitekey="6Lca9O0fAAAAAOYOWN7R-tVq0DkcJGz0aaSrFPNE" @verify="enableBtn()" @expired="disableBtn()"></vue-recaptcha>

      <button id="submit" class="btn" disabled="true" @click="validate()">Submit</button>
    </div>

    <div id="thanks" v-show="submitted">
      <h4>Thank you for your message!  We will be back in touch with you shortly.</h4>
    </div>


  </div>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';
export default {
  name: 'contact',
  components: { VueRecaptcha },
  data: function() {
    return {
      name: '',
      email: '',
      message: '',
      submitted: false
    }
  },
  methods: {
    enableBtn: function() {
      document.getElementById("submit").disabled=false;
    },
    disableBtn: function() {
      document.getElementById("submit").disabled=true;
    },
    validate: function() {
      var self = this;

      if (this.name == '' || this.email == '' || this.message == '') {
        alert('Please complete all fields to submit a message.');
        return;
      }
      var URL = 'https://d3in65hxdstkj9.cloudfront.net/api/contact';

      var data = {
        name: this.name,
        email: this.email,
        message: this.message,
      }

      fetch(URL, {
        method: 'POST',
        mode: 'cors',
        headers: {
          "Content-type": "application/json"
        },
        body: JSON.stringify(data)
      })
      .then(function (response) {
        return response.json();
      })
      .then(function (json) {
        // console.log(json);
        if (json.body == 'Success!')  {
          self.submitted = true;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../assets/settings.scss";
h4 {
  margin: 16px 0 4px 0;
}

.card {
  width: 50%;
  display: inline-block;
}

input {
  width: calc(100% - 16px);
  height: 32px;
  line-height: 48px;
  font-size: 18px;
  padding: 8px;
  border: 1px solid $color-text;
  border-radius: 4px;
}
.left input {
  width: calc(100% - 48px);
}

textarea {
  width: calc(100% - 16px);
  height: 300px;
  font-family: $font;
  font-size: 18px;
  padding: 8px;
  border: 1px solid $color-text;
  border-radius: 4px;
}

button:disabled {
  border: 1px solid $color-disabled;
  color: $color-disabled;

}
button:disabled:hover {
  border: 1px solid $color-disabled;
  color: $color-disabled;
}
button:hover {
  border: 1px solid $color-main;
  color: $color-main;
}


.btn {
  border: 1px solid $color-text;
  border-radius: 4px;
  width: 100px;
  padding: 8px;
  text-align: center;
  cursor: pointer;
  margin-top: 20px;
  font-size: 18px;
  height: 50px;
  background: #FFFFFF;
}
.btn:active {
	transition: color 0s;
	color: $color-main;
	outline: 0;
  border-color: $color-main;
}

span {
  display: block;
}

#recaptcha {
  margin-top: 20px;
}

#thanks {
  padding-top: 48px;
  padding-bottom: 48px;
}

@media screen and (max-width: 880px) {

  .card {
    width: 100%;
  }
  .left input {
    width: calc(100% - 16px);
  }

}

</style>
