<template>
  <div>

    <h1>Lessons</h1>

    <img class="rphoto" alt="Teaching the Suzuki Method" src="../assets/IMG_1824.png">

    <p>Violin Artistry Studio teaches children of all ages and levels, incorporating the philosophy of the Suzuki Method™. Private lessons are tailored to each student's needs, interests and learning capabilities. If you are a parent of a young child, visit the <a href="https://violinartistry.com/suzuki-approach">Suzuki Approach</a> Page for more information.</p>
    <p>Lessons are available in English and Japanese, and offered in-person or online. It is recommended that beginners receive in-person lessons, due to the complexity of the instrument.  To take lessons with Dr. Nikki Routman Ebisu, please <a href="https://violinartistry.com/contact">contact us</a>.</p>

    <h2>Tuition Rates</h2>

    <p>Violin Artistry Studio is a Violin Artistry Studio is a tuition-based educational program and commitment. Enrollment entitles the student to studio membership, a monthly group lesson, and weekly private instruction at a designated time slot each week, reserved exclusively for your child. Other events might include recitals, holiday performances, community service and competitions. Students may also participate in the local Youth Symphony when they are age eligible (age 8-18).</p>

    <p>The per-lesson rates are listed below. Tuition is paid monthly. General Excise tax of 4.712% is included in the rate.</p>

    <h3>30 minutes: $55.00</h3>
    <h3>45 minutes: $80.00</h3>
    <h3>60 minutes: $100.00</h3>

    <p><i>Lessons are also offered online via Zoom. Accepting online students from anywhere in the world. Payable via Venmo or Paypal.</i></p>
    <br>

    <h2 class="clear">Materials for Lessons</h2>

    <div class="card">
      <h3>Instrument</h3>
      <p>Please rent or purchase a violin before the first lesson. The local music store can help you with sizing. Young students age 3 and younger may use a foam training violin until they are ready to "graduate" to the real violin. We can determine if they are ready at the initial consultation. I recommend renting (instead of purchasing) a violin until you are sure about your child's commitment. The music stores rent for about $30/month plus an initial deposit.</p>
    </div>
    <div class="card">
      <h3>Textbooks</h3>
      <p><i>Suzuki Method, Revised Violin Volume 1 (Book & CD)</i> — Hilary Hahn recording</p>

      <p><i>Step by Step, Volume 1A</i> by Kerstin Wartberg (Book & CD)</p>

      <p><i>I Can Read Music for Violin, Volume 1</i></p>

      <p>All of these items are available at the studio. You may also purchase them online.</p>
    </div>
    <div class="card">
      <h3>Shoulder Rest</h3>
      <p>Your violin does not come with the required shoulder rest when you purchase or rent the violin, so please know that you will need to purchase a shoulder rest at your first lesson.  I typically suggest the ‘Everest’ brand since they fit best for young students and kids enjoy the vibrant colors. Color choices include hot pink, light pink, blue, purple, green, orange, red and black. You can purchase this in my studio, or at my online store:  <a href="https://alohatuners.com" target="_blank" rel="noopener">alohatuners.com</a>.</p>
      <p>If your child is between the ages of 3 and 5: A contoured foam is a good choice for younger students using size 1/32 through 1/10 violins.</p>
    </div>
    <div class="card">
      <h3>Rosin</h3>
      <p>Rosin is hardened tree sap from a pine tree. You swipe it on the bow every time before you play, and it leaves a white powder on the bow hair. It makes the bow hair stickier, thus being able to grab onto the strings more and allow for a bigger sound. It is not used to clean the hair!! Your case will usually come with low-grade rosin when you rent or purchase a violin.</p>
      <p> Rosin will become hard or sticky on the violin surface without proper care and maintenance, so you must wipe the violin after practicing. See "cleaning cloth" info.</p>
      <p>If you want a good-quality Rosin, I recommend Bernadel Rosin.</p>
    </div>
    <div class="card">
      <h3>Cleaning Cloth</h3>
      <p>Please keep a cleaning cloth in your case to wipe off the rosin and dust off your violin and bow. Flannel material or cotton works best. You may also use a handkerchief or an old cubut you must wash it frequently. Do not use towel material. You can store it on top of the violin like a blanket to protect the bridge.</p>
    </div>
    <div class="card">
      <h3>Tuner</h3>
      <p>Every student will require a tuner in order to make sure the violin is tuned before practicing. I like the Cleartune App, or the cute animal clip-on tuners at <a href="https://alohatuners.com" target="_blank" rel="noopener">alohatuners.com</a>.</p>
    </div>

    <h2 id="faq">Frequently Asked Questions</h2>

    <div class="card">
      <h3>What is the recommended age to begin lessons?</h3>
      <p>In Dr. Suzuki’s book “Nurtured by Love”, Dr. Suzuki recommends beginning music lessons as young as three years old.</p>
      <p>At the Violin Artistry Studio, lessons begin when the Instructor feels that the student is ready. Every child develops at different rates with different personalities, so many teachers follow these minimum guidelines:</p>
      <p><i>Is your child willing to receive instruction?</i> It is totally acceptable to be shy and not want to talk. But if a child is crying the whole lesson then they may not be ready.</p>
      <p><i>Can your child sit at least 10 minutes</i> without having to crawl up onto Mommy or Daddy's lap or rolling all over the floor, running away or hiding behind the chair?</p>
      <p><i>Can the parent and child commit to practicing at least 5-10 minutes everyday, consistently at home?</i></p>
    </div>
    <div class="card">
      <h3>I read that the Suzuki Method requires parental involvement. What does this mean?</h3>
      <p>Parents play a vital role in the child's music education, especially when the child is young. Dr. Suzuki believed that music instruction forms a triangle between the student, parent and teacher. If one is off-balance, either from lack of communication or support, the triangle is no longer stable.</p>
      <p>At home, the parent is the "home teacher". He or she must play an active role as the helper while maintaining a nurturing environment for the child. Violin Artistry requires parents of young students to read the following books to better understand the philosophy of the Suzuki Method and to know how to practice effectively with the child. It is essential that the parent/guardian also follow and comprehend what is going on in lessons. For the child to succeed, the parent must also understand. Some parents rent a violin and take lessons concurrently with the child to practice together and inspire the child, but this is optional.</p>
      <br>
    </div>
    <div class="card">
      <h3>Are the parents required to sit in the lesson?</h3>
      <p>For students under age 10, parents must be involved and committed. In the Suzuki Method, parents many not drop off the child and be disengaged from the lesson. Note-taking, photos and videos are great ways to foster the child’s learning.</p>
      <p>The minimum lesson length is 30 minutes, no matter the age of the child. The majority of the lesson is spent between teacher and child, and the remainder of the lesson is spent educating the parent how to practice with the child at home.</p>
      <p>Students in Books 1 - 3 typically take 30 minute lessons. Students in Books 4 - 6 take 45 minute lessons, and Books 7 through Advanced levels take 1 hour weekly lessons.</p>
    </div>
    <div class="card">
      <h3>What materials do I need to begin lessons?</h3>
      <p>You will need:</p>
      <ol>
        <li>An instrument & bow (correct size)</li>
        <li>Suzuki Violin Revised Volume 1  (Book and CD) recorded by Hilary Hahn</li>
        <li>Step-by-Step Volume 1 by Kerstin Wartberg</li>
        <li>Shoulder rest or sponge</li>
        <li>Rosin</li>
        <li>Cleaning Cloth - cotton or flannel</li>
        <li>I Can Read Music, Violin Volume 1 (older students only)</li>
        <li>A tuner (to tune the violin before practice). I like the Cleartune app, or the cute animal <a href="https://alohatuners.com" target="_blank" rel="noopener">clip-on tuners</a>.</li>
      </ol>
    </div>
    <div class="card">
      <h3>What are the expectations?</h3>
      <p>Music is like learning a language -- it is a long-term commitment for both the child and parent. It requires small steps, constant repetition, encouragement and daily practice. Dr. Suzuki philosophy (which he called the Mother-Tongue Method) and his Suzuki Method is built on the foundation that "Every Child Can©" learn music if taught in the right environment.</p>
      <p>Practicing must be incorporated into the child's daily routine, just like eating meals, doing homework or bathing. Dr. Suzuki used to say <b>"Only practice on the days you eat."</b> Musicians rely on "muscle memory" -- training the muscles (the ones in our body as well as our brain) to learn new skills. Musicians are like athletes, but use much smaller muscles. We must condition daily or else we lose what we gained that week and have to start all over. It requires time, discipline, motivation and the proper mindset to be a Suzuki™ parent and student.</p>
    </div>
    <div class="card">
      <h3>Do you offer lessons online?</h3>
      <p>Since virtual learning is part of our culture now, Violin Artistry Studio also offers violin lessons online. We utilize Zoom for lessons, and use Facetime as a backup. Violin Artistry accepts students from anywhere in the world. Lessons are taught in English or Japanese.</p>
    </div>

  </div>
</template>

<script>
export default {
  name: 'lessons'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../assets/settings.scss";

.card {
  vertical-align: top;
  display: inline-block;
  width: calc(33% - 64px);
  padding-right: 32px;
}

#faq {
  margin-top: 60px;
}
@media screen and (max-width: 1000px) {
  .card {
    width: 100%;
  }
}

</style>
